<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <form-group input-id="country" :has-error="hasError">
        <template v-slot:label>Country of Card Issuance</template>
        <template v-slot:icon>
            <i class="mdi mdi-flag"></i>
        </template>
        <template v-slot:input>
            <select id="country" v-model="country" name="country" class="form-control">
                <option value=""></option>
                <option v-for="(c, key) in validCountries" :key="`country-${key}`" :value="c.code">{{ c.name }}</option>
            </select>
        </template>
        <template v-slot:error v-if="hasError">
            Select a country before you continue
        </template>
    </form-group>
</template>

<script>
    import { mapState, mapGetters, mapMutations } from 'vuex'
    import FormGroup from "./FormGroup";
    import lo from 'lodash';
    import constants from "../constants";
    export default {
        name: "ValidCountries",
        components: {FormGroup},
        computed: {
            ...mapState(['countries']),
            ...mapGetters({
                payment: constants.GET_PAYMENT
            }),
            validCountries() {
                // if (this.payment) {
                //     if (this.payment.currency === 'UGX')
                //         return this.filterCountries(['UG']);
				//
                //     if (this.payment.currency === 'KES')
                //         return this.filterCountries(['KE']);
                // }

                return this.countries;
            },
            country: {
                get() {
                    return this.$store.state['country'];
                },
                set(value) {
                    this.updateCountry(value);
                }
            },
            hasError() {
                return !this.country;
            }
        },
        methods: {
            ...mapMutations({
                updateCountry: constants.UPDATE_COUNTRY
            }),
            filterCountries(codes) {
                return lo.filter(this.countries, (country) => {
                    return lo.includes(codes, country.code);
                });
            },
            rejectCountries(codes) {
                return lo.reject(this.countries, (country) => {
                    return lo.includes(codes, country.code);
                });
            }
        }
    }
</script>

<style scoped>

</style>