<template>
    <div class="card main">
        <slot name="card">
            <div class="card-header p-0">
                <div class="list-group list-group-flush">
                    <div class="list-group-item d-flex border-top-radius py-1">
                        <img src="@/assets/images/logo.png" class="my-auto w-25"/>
                        <a id="close" v-if="enableExit && origin"
                           class="btn btn-link btn-lg text-danger ml-auto my-auto p-0 label"
                           @click="exit()">
                            <i class="mdi mdi-close-circle"></i> Close Payment Modal
                        </a>
                    </div>
                    <template v-if="!content">
                        <template v-if="fees">
                            <div class="list-group-item d-flex">
                                <span class="text-muted my-auto" style="font-weight: lighter">Sub Total</span>
                                <div class="my-auto ml-auto text-center text-dark ">
                                    <span class="text-muted text-uppercase" style="font-weight: lighter; font-size: x-small">{{ payment.currency }}</span>
                                    <span class="text-dark ml-1" style="font-weight: bold">{{ commas(basePayment.amount) }}</span>
                                </div>
                            </div>
                            <div class="list-group-item d-flex">
                                <span class="text-muted my-auto" style="font-weight: lighter">Fee</span>
                                <div class="my-auto ml-auto text-center text-dark">
                                    <span class="text-muted text-uppercase" style="font-weight: lighter; font-size: x-small">{{ payment.currency }}</span>
                                    <span class="text-dark ml-1" style="font-weight: bold">{{ commas(fees) }}</span>
                                </div>
                            </div>
                        </template>
                        <div class="list-group-item d-flex">
                            <span class="text-dark my-auto" style="font-weight: bold">Total</span>
                            <div class="my-auto ml-auto text-center">
                                <p class="text-dark m-0">
                                    <span class="text-muted text-uppercase" style="font-weight: lighter; font-size: x-small">{{ payment.currency }}</span>
                                    <span class="text-dark ml-1" style="font-weight: bold">{{ commas(payment.amount) }}</span>
                                </p>
                                <p class="text-muted m-0" style="font-weight: lighter; font-size: x-small">{{ payment.email }}</p>
                            </div>
                        </div>
	                    <div class="list-group-item text-center text-danger">{{ text }}</div>
                    </template>
                </div>
            </div>
            <slot name="content">
                <div class="card-body p-0 scrollable bg-light">
                    <slot name="body"></slot>
                </div>
                <slot name="footer"></slot>
            </slot>
        </slot>
    </div>
</template>

<script>
import {mapActions, mapGetters, mapState} from 'vuex'
    import constants from "../constants";
    export default {
        name: "PageTemplate",
	    data() {
			return {
				text: 'Please Note: Extra fees from third party service providers may apply.'
			}
	    },
        props: {
            enableExit: {
                type: Boolean,
                default: true
            },
            content: {
                type: Boolean,
                default: false
            }
        },
        computed: {
          ...mapState(['origin']),
            ...mapGetters({
                payment: constants.GET_PAYMENT,
                basePayment: constants.GET_BASE_PAYMENT,
                fees: constants.GET_FEES
            })
        },
        methods: {
            ...mapActions({
                exit: constants.CLOSE
            }),
            commas(num) {
                return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
            }
        },
    }
</script>

<style scoped>

</style>