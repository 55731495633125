<template>
	<momo-page :processing="processing" :content="redirectRequired">
		<template v-slot:content v-if="redirectRequired">
			<iframe class="h-100 w-100 border-0" :src="redirect"></iframe>
		</template>
		<template v-slot:page v-else>
			<p class="text-center pt-4 text-uppercase label">Enter your mobile money number</p>
			<div class="m-4 px-4 pb-4 payment-form">
				<error-alert v-if="error" v-on:close="setError">
					{{ error }}
				</error-alert>

				<success-alert v-if="success" v-on:close="setSuccess">
					{{ success }}
				</success-alert>

				<Form @submit="initiate" v-slot="{ values, errors }">
					<form-group input-id="phone-number" :has-error="errors.phoneNumber !== undefined">
						<template v-slot:label>
							Phone number (With country code)
							<span v-if="countryCode(values.phoneNumber)">
                  - <span :class="`fi fi-${lower(countryCode(values.phoneNumber))}`"></span>
              </span>
						</template>
						<template v-slot:icon>
							<i class="mdi mdi-phone-outline"></i>
						</template>
						<template v-slot:input>
							<field id="phone-number" name="phoneNumber" v-model="phoneNumber" type="text" class="form-control" :readonly="processing" placeholder="256785000000" :rules="validatePhoneNumber"/>
						</template>
						<template v-slot:error>
							<error-message name="phoneNumber"/>
						</template>
					</form-group>

					<pay-button :class="{disabled: processing || errors.phoneNumber !== undefined}"></pay-button>
				</Form>

				<div class="text-center mt-2" v-if="processing">
					<progress-loader></progress-loader>
					<p class="m-0">Processing...</p>
				</div>
			</div>
		</template>
	</momo-page>
</template>

<script>
import {Form, Field, ErrorMessage} from 'vee-validate';
import {mapGetters, mapActions, mapState, createNamespacedHelpers} from 'vuex';

const {mapMutations} = createNamespacedHelpers('momo');
import constants from "../constants";
import MomoPage from "../components/MomoPage";
import PayButton from "../components/PayButton";
import ProgressLoader from "../components/Loader";
import FormGroup from "../components/FormGroup";

import {parsePhoneNumberWithError, ParseError} from 'libphonenumber-js'
import {lowerCase, replace, capitalize, kebabCase, includes, debounce} from 'lodash';
import ErrorAlert from "../components/ErrorAlert";
import api from "../api";
import SuccessAlert from "../components/SuccessAlert";

export default {
	name: "MobileMoney",
	components: {SuccessAlert, ErrorAlert, ErrorMessage, Field, Form, FormGroup, ProgressLoader, PayButton, MomoPage},
	data() {
		return {
			processing: false,
			error: null,
			success: null
		}
	},
	computed: {
		...mapState(['origin', 'momo_driver']),
		...mapGetters({
			payment: constants.GET_PAYMENT,
			response: constants.GET_TRANSACTION,
			driver: constants.GET_DRIVER
		}),
		phoneNumber: {
			get() {
				return this.$store.state['momo/phone_number'];
			},
			set(value) {
				this.updatePhoneNumber(value);
			}
		},
		redirectRequired() {
			if (this.driver === constants.FLUTTERWAVE)
				return !!(this.response && this.response.data && this.response.data.meta
					&& this.response.data.meta.authorization && this.response.data.meta.authorization.redirect);

			return false;
		},
		redirect() {
			if (this.redirectRequired)
				if (this.driver === constants.FLUTTERWAVE)
					return this.response.data.meta.authorization.redirect;

			return null;
		},
		isMpesaPending() {
			return !!(this.response && this.response.data && this.response.data.data && this.response.data.data.auth_model === 'LIPA_MPESA');
		}
	},
	methods: {
		...mapMutations({
			updatePhoneNumber: constants.UPDATE_PHONE_NUMBER
		}),
		...mapActions('momo', {
			pay: constants.PAY_ACTION
		}),
		...mapActions({
			setTransaction: constants.SET_TRANSACTION
		}),
		lower(value) {
			return lowerCase(value);
		},
		countryCode(value) {
			try {
				const phoneNumber = parsePhoneNumberWithError(`+${replace(value, "+", "")}`);

				if (phoneNumber.isValid()) {
					return phoneNumber.country;
				}

			} catch (error) {
				return null;
			}

			return null;
		},
		validatePhoneNumber(value) {
			if (!value) {
				return 'Please enter a valid phone number';
			}

			try {
				const phoneNumber = parsePhoneNumberWithError(`+${replace(value, "+", "")}`);

				if (!phoneNumber.isValid()) {
					return 'Please enter a valid phone number';
				}

			} catch (error) {
				if (error instanceof ParseError) {
					// Not a phone number, non-existent country, etc.
					return capitalize(replace(kebabCase(error.message), '-', " "));
				} else {
					throw error
				}
			}

			return true;
		},
		initiate() {
			if (!this.processing) {
				this.processing = true;
				this.pay(this.payment)
					.then((response) => {
						this.setTransaction(response.data);
					})
					.then(() => {
						if (this.driver === constants.CELLULANT) {
							if (this.response && this.response.data && this.response.data.encrypted) {
								const redirect = `${this.cellulantCheckoutUrl}?access_key=${this.cellulantAccesskey}&encrypted_payload=${this.response.data.encrypted}`;
								window.open(redirect, '_blank');
								// this.$cellulantCheckout(this.response.data.encrypted);
							}
						}
					})
					.then(() => {
						if (this.response && this.response.id) {
							if (includes(constants.POLL, this.driver) || this.isMpesaPending) {
								let checkStatus = debounce(this.checkTransactionStatus, 5000);
								checkStatus();
							} else {
								this.processing = false;
							}
						}
					})
					.catch(() => {
						this.processing = false;
						this.setError("An error occurred, please try again!");
					});
			}
		},
		setError(error) {
			this.error = error;
			this.success = null;
		},
		setSuccess(success) {
			this.success = success;
			this.error = null;
		},
		checkTransactionStatus() {
      const success = "The payment was completed successfully"
			if (this.response && this.response.id) {
				if (includes(constants.POLL, this.driver) || this.isMpesaPending) {
					api.showTransaction(this.response.id)
						.then((response) => {
							this.setTransaction(response.data);
						})
						.then(() => {
							if (this.response.status !== null) {
								if (this.response.status === 'successful') {
									this.processing = false;
									if (this.driver === constants.AIRTEL_UG) {
										this.setSuccess(success)
									}

									if (includes([constants.MTN, constants.FLUTTERWAVE, constants.CELLULANT], this.driver)) {
										this.setSuccess(success)
									}
								} else {
									this.processing = false;
									if (this.driver === constants.AIRTEL_UG) {
										this.setError(this.response.data.data.transaction.message);
									}

									if (this.driver === constants.MTN) {
										let message = 'An error occurred, please try again!';
										if (this.response.data.reason === 'APPROVAL_REJECTED') {
											message = "Payment approval was rejected by user";
										}

										if (this.response.data.reason === 'INTERNAL_PROCESSING_ERROR') {
											message = "There was an internal processing error";
										}

										if (this.response.data.reason === 'EXPIRED') {
											message = "Payment approval timed out";
										}

										this.setError(message)
									}

									if (this.driver === constants.FLUTTERWAVE) {
										this.setError("The payment failed!")
									}

									if (this.driver === constants.CELLULANT) {
										this.setError('An error occurred, please try again!');
									}
								}
							}
						})
						.then(() => {
							if (this.processing) {
								let checkStatus = debounce(this.checkTransactionStatus, 5000);
								checkStatus();
							}
						})
						.catch(() => {
							this.processing = false;
							this.setError("An error occurred, please try again!");
						});
				}
			}
		}
	}
}
</script>

<style scoped>

</style>